
import { ScrollView, View } from "react-native";
import { LongText, Teacher, Title, WeirdText } from "./utils";

export default function Class9_7() {
  return (
    <ScrollView
      style={{
        padding: 20,
      }}
    ><Title></Title>
<Teacher name="Patrónusok:" subjects={['']} />
<Teacher name="Hegymegi Istvánné" subjects={['Természettudomány']} />
<Teacher name="Juhász Iván" subjects={['Irodalom','Magyar nyelv']} />
<Teacher name="Kelemen Veronika" subjects={['Természettudomány']} />
<Teacher name="Laffer Zsuzsanna" subjects={['Évfolyam-osztályfőnök']} />


<Title>Az évfolyamon tanító tanárok:</Title>
<Teacher name="Bajkó Bálint" subjects={['Történelem','Etika-társdalomismeret (1','3)']} />
<Teacher name="Burján Orsolya" subjects={['Ének-zene']} />
<Teacher name="Izsóné Hubay Katalin" subjects={['Angol nyelv (1','2)']} />
<Teacher name="Jakab Péter" subjects={['Testnevelés']} />
<Teacher name="Kuczó Csilla" subjects={['Angol nyelv (3','4)']} />
<Teacher name="Szabó Imre" subjects={['Rajz és vizuális kultúra']} />
<Teacher name="Szondi György" subjects={['Matematika']} />
<Teacher name="Nógrádi Zoltán" subjects={['Etika-társadalomismeret (2','4)']} />



<Title>Szülői értekezletek:</Title>
<WeirdText>2022. szeptember 14. (szerda) 17.00 óra</WeirdText>
<WeirdText>2023. február 22. (szerda) 17.00 óra</WeirdText>

<Title>Fogadóórák:</Title>
<WeirdText>2022. november 16. (szerda) 17.00 óra</WeirdText>
<WeirdText>2023. március 22. (szerda) 17.00 óra</WeirdText>

<Title>Szülői klubok:</Title>
<WeirdText>2022. október 19. (szerda) 17.00 óra</WeirdText>
<WeirdText>2023. április 26. (szerda) 17.00 óra</WeirdText>

<Title>Év végi vizsga:</Title>
<WeirdText>Vizsgahúzás június 2-án pénteken a sportnapon 14 órakor.</WeirdText>
<WeirdText>Vizsgatárgyak:</WeirdText>
<WeirdText>· irodalom</WeirdText>
<WeirdText>· matematika</WeirdText>
<WeirdText>· történelem</WeirdText>
<WeirdText>· természettudomány</WeirdText>
<WeirdText>A vizsgatárgy húzása csibénként történik.</WeirdText>
<WeirdText>A vizsga a következő héten csütörtökön vagy pénteken (június 8-9.) lesz erről a szaktanár ad tájékoztatást.</WeirdText>
<WeirdText>A felkészülés önálló de konzultációra van lehetőség. A vizsgakövetelmények és minden információ felkerülnek minden tantárgyból az AKG honlapjára (www.akg.hu/vizsga).</WeirdText>
<WeirdText></WeirdText>

    </ScrollView>
    );
  }

