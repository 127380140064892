import * as React from "react";
import {
  View,
  Text,
  useColorScheme,
  StyleSheet,
  SafeAreaView,
  Image,
  FlatList,
} from "react-native";
import WebView from "react-native-webview";
import { createDrawerNavigator } from "@react-navigation/drawer";

import {
  DrawerContentScrollView,
  DrawerItemList,
} from "@react-navigation/drawer";
import Class07K from "./tanaroldalak/class_07k";
import Class07 from "./tanaroldalak/07";
import Class08 from "./tanaroldalak/08";
import Class08k from "./tanaroldalak/08k";
import Class10_7 from "./tanaroldalak/10_7";
import Class11ny from "./tanaroldalak/11ny";
import Class12 from "./tanaroldalak/12";
import Class12n from "./tanaroldalak/12n";
import Class9_7 from "./tanaroldalak/9_7";
import Class9k from "./tanaroldalak/9k";

const Drawer = createDrawerNavigator();

function BookScreen() {
  const styles = StyleSheet.create({
    container: {
      flex: 1,
      // marginTop: 21
    },
  });

  function CreateRandomComponent() {
    return (
      <View>
        <Text>
          This is a component with a randomness of {Math.random().toString()}
        </Text>
      </View>
    );
  }

  function BookAtPage(page) {
    return (
      <Drawer.Screen
        name="error?"
        component={() => (
          <WebView
            source={{
              //   uri: `https://www.akg.hu/wp-content/uploads/2021/09/diaknaptar2021.pdf#page${page}`,
              uri: "./assets/diaknaptar2021.pdf",
            }}
            onNavigationStateChange={(navState) => {
              console.log(navState);
            }}
            onLoadEnd={() => {
              console.log("loaded!");
            }}
          />
        )}
      />
    );
  }

  const BookDataPoints = {
    "Alternatív Közgazdasági Gimnázium Alapítvány": "4",
    "Az iskolára vonatkozó adatok": "5",
    "Az AKG pedagógusai, elérhetőségek, tanított tárgyak": "6",
    "Iskolai eseménynaptár": "10",
    "Kiscelli utcai épület fontos információk": "22",
    "1-6. évfolyamok éves programja és program tematika": "24",
    "1-6. évfolyamok napirendje": "32",
    "Vadmacska ház 1. évfolyam munkarendje": "33",
    "Bagoly ház 2. évfolyam munkarendje": "33",
    "Vadmacska ház 3. évfolyam munkarendje": "34",
    "Bagoly ház 4. évfolyam munkarendje": "34",
    "Szalamandra ház 5. évfolyam munkarendje": "35",
    "Szalamandra ház 6. évfolyam munkarendje": "35",
    "Kedd délutáni műhelyek az 3-6. évfolyamon": "36",
    "Értékelési rendszer": "37",
    "7K.8K. évfolyam": "46",
    "Kiscelli könyvtár": "54",
    "Zeneiskola a Kiscelliben": "55",
    "Raktár utcai épület fontos információk": "56",
    "7. évfolyam": "58",
    "8. évfolyam": "66",
    "9. évfolyam": "74",
    "10. évfolyam": "82",
    "11/7ny. évfolyam": "90",
    "11. évfolyam": "92",
    "12. évfolyam": "94",
    "9/5. évfolyam": "96",
    "10/5. évfolyam": "104",
    "11/5ny. évfolyam": "112",
    "11/5n. évfolyam": "114",
    "12/5. évfolyam": "116",
    "Alkotó- és szakkörök": "118",
    "Értékelési rendszer - gimnáziumi évfolyamok": "123",
    "Könyvtár - gimnáziumi évfolyamok": "130",
    "Kötelező olvasmányok": "131",
    "Étkezés az AKG-ban": "135",
    "Összesített naptár a 2021, 2022, 2023 évekre": "141",
    "Heti tervező naptár": "144",
  };

  // const classScreens = {
  //   "07": Class07,
  //   "07k": Class07K,
  //   "08": Class08,
  //   "08k": Class08k,
  //   "10_7": Class10_7,
  //   "11ny": Class11ny,
  //   "12": Class12,
  //   "12n": Class12n,
  //   "9_7": Class9_7,
  //   "9k": Class9k,
  // }

  const classScreens = [
    ["7. évfolyam munkarendje", Class07],
    ["7K. évfolyam munkarendje", Class07K],
    ["8. évfolyam munkarendje", Class08],
    ["8K. évfolyam munkarendje", Class08k],
    ["9. évfolyam munkarendje", Class9_7],
    ["9K. évfolyam munkarendje", Class9k],
    ["10. évfolyam munkarendje", Class10_7],
    ["11ny évfolyam munkarendje", Class11ny],
    ["12. évfolyam munkarendje", Class12],
    ["12/7N évfolyam munkarendje", Class12n],
  ];

  return (
    <View style={styles.container}>
      {/* <StatusBar /> */}
      <Drawer.Navigator
        screenOptions={{
          headerTintColor: "#ffffff",
          headerLeftContainerStyle: {
            color: "#ffffff"
          },
          headerStyle: {
            textAlign: "center"
          },
        }}
      >
        {classScreens.map((e) => {
          return (
            <Drawer.Screen
              name={e[0]}
              key={`${e[0]}`}
              onNavigationStateChange={false}
              component={e[1]}
            >
              {/* {() => (
                <WebView
                  source={{
                    uri: `https://www.akg.hu/wp-content/uploads/2021/09/diaknaptar2021.pdf#page${e[1]}`,
                  }}
                />
              )} */}
            </Drawer.Screen>
          );
        })}
      </Drawer.Navigator>
    </View>
  );
}

export default BookScreen;
