import { useEffect, useState } from "react";
import { View, Text, FlatList, ActivityIndicator } from "react-native";

function Bus(props) {
  return (
    // <Text style={{
    //   color: "#ffffff",
    //   paddingBottom: 20
    // }} key={`bus_${i}`}>
    //   {JSON.stringify(e)}
    // </Text>
    <View
      style={{
        flex: 1,
        flexDirection: "row",
        // alignContent: "center"
        justifyContent: "center",
      }}
    >
      <View
        style={{
          paddingVertical: 10,
          paddingHorizontal: 5,
          alignItems: "center",
        }}
      >
        <View
          style={{
            backgroundColor: "#009ee3",
            width: 75,
            height: 40,
            borderRadius: 10,
            justifyContent: "center",
            alignItems: "center",
            // flex: 1
          }}
        >
          <Text
            style={{
              color: "#ffffff",
              fontWeight: 600,
              fontSize: 23,
            }}
          >
            {props.number}
          </Text>
        </View>
      </View>
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          // padding: 20,
          paddingHorizontal: 20,
        }}
      >
        <Text
          style={{
            color: "#ffffff",
            fontWeight: 600,
            fontSize: 18,
          }}
        >
          {props.sign}
        </Text>
      </View>
      <View
        style={{
          justifyContent: "center",
          // padding: 20,
        }}
      >
        <Text
          style={{
            color: "#ffffff",
            fontWeight: 600,
            fontSize: 18,
          }}
        >
          '{props.minutes}
        </Text>
      </View>
    </View>
  );
}

function LoadingScreen(props) {
  return (
    <View
      style={{
        flex: 1,
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      <ActivityIndicator size={"large"} />
    </View>
  );
}

export default function Buses() {
  const [buses, setBuses] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!loading) {
      return false;
    }

    fetch(
      "https://futar.bkk.hu/api/query/v1/ws/otp/api/where/arrivals-and-departures-for-stop.json?key=apaiary-test&version=2&appVersion=apiary-1.0&includeReferences=true&stopId=BKK_F00583&onlyDepartures=false&limit=60&minutesBefore=0&minutesAfter=30"
    )
      .then((e) => e.json())
      .then((e) => {
        const buses = [];
        e.data.entry.stopTimes.forEach((bus) => {
          const routeId = Object.entries(e.data.references.trips).find(
            (e) => e[0] == bus.tripId
          )[1].routeId;

          buses.push({
            sign: bus.stopHeadsign,
            tripId: bus.tripId,
            shortName: e.data.references.routes[routeId].shortName,
            minutes: Math.floor(
              (bus.predictedArrivalTime - Math.floor(Date.now() / 1000)) / 60
            ),
            // minutes: bus.predictedArrivalTime
          });
        });
        // setBuses(e.data.entry.stopTimes)
        console.log(buses);
        setBuses(buses);
        setLoading(false);
      });
  }, [loading]);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <FlatList
      style={{
        // padding:
        paddingHorizontal: 8,
        paddingTop: 6
      }}
      refreshing={true}
      onRefresh={() => setLoading(true)}
      data={buses.filter((e) => e.minutes)}
      renderItem={({ item }) => (
        <Bus sign={item.sign} number={item.shortName} minutes={item.minutes} />
      )}
      keyExtractor={(b) => b.tripId}
    />
  );
}
