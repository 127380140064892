
import { ScrollView, View } from "react-native";
import { LongText, Teacher, Title, WeirdText } from "./utils";

export default function Class08k() {
  return (
    <ScrollView
      style={{
        padding: 20,
      }}
    ><Title>Patrónusok:</Title>
<Teacher name="Csicskár-Miátovics Krisztina" subjects={['kisiskola-képviselő','testnevelés','gyógytestnevelés','úszás']} />
<Teacher name="Huszár-Walla Júlia" subjects={['kisiskola-képviselő','angol','tanulásmódszertan']} />
<Teacher name="Philipp György" subjects={['ének']} />
<Teacher name="Varró Mária" subjects={['történelem']} />


<Title>Az évfolyamon tanító tanárok:</Title>
<Teacher name="Gottweisz Tímea" subjects={['irodalom']} />
<Teacher name="Dr. Pethő Szilvia" subjects={['matematika']} />
<Teacher name="Erdei Edina" subjects={['természettudomány']} />
<Teacher name="Tóth István" subjects={['természettudomány']} />
<Teacher name="Basa Renáta" subjects={['matematika']} />
<Teacher name="Dezséri Csaba" subjects={['informatika']} />
<Teacher name="Tímár Attila" subjects={['angol']} />
<Teacher name="Hanula Erika" subjects={['angol','tanulásmódszertan']} />
<Teacher name="Fenyvesi Edit" subjects={['vizuális kultúra']} />
<Teacher name="Létay Márton Károly" subjects={['magyar nyelvtan']} />



<Title>Szülői értekezletek:</Title>
<WeirdText>2022. szeptermber 14. (szerda) 17.00 óra</WeirdText>
<WeirdText>2023. február 22 (szerda) 17.00 óra</WeirdText>

<Title>Fogadóórák:</Title>
<WeirdText>2022. november 16 (szerda) 17.00 óra</WeirdText>
<WeirdText>2023. március 29 (szerda) 17.00 óra</WeirdText>

<Title>Szülői klubok:</Title>
<WeirdText>2022. december 7. (szerda) 17.00 óra</WeirdText>

<Title></Title>
<WeirdText>Év végi vizsga:</WeirdText>
<WeirdText>Vizsgahúzás június 2-án pénteken</WeirdText>
<WeirdText>a sportnapon 14 órakor.</WeirdText>
<WeirdText>Vizsgatárgyak:</WeirdText>
<WeirdText>• irodalom</WeirdText>
<WeirdText>• matematika</WeirdText>
<WeirdText>• történelem</WeirdText>
<WeirdText>• természettudomány</WeirdText>
<WeirdText>• joker</WeirdText>
<WeirdText>A vizsgatárgy húzása csibénként történik. A vizsga a következő héten csütörtökön vagy pénteken (június 9-10.)lesz, erről a szaktanár ad tájékoztatást. A felkészülés önálló, de konzultációra van lehetőség. A vizsgakövetelmények és minden információ felkerülnek minden tantárgyból az AKG honlapjára (www.akg.hu/vizsga).</WeirdText>

    </ScrollView>
    );
  }

