import { StatusBar } from "expo-status-bar";
import { Pressable, StyleSheet, Text, View } from "react-native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";

import * as React from "react";
import {
  DarkTheme,
  NavigationContainer,
  StackActions,
} from "@react-navigation/native";
import BookScreen from "./screens/Book";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import HomeScreen from "./screens/Home";
import Schedule from "./screens/Schedule";
import { SafeAreaProvider } from "react-native-safe-area-context";
import Buses from "./screens/Buses";

import { Ionicons } from "@expo/vector-icons";
import MayorScreen from "./screens/Mayor";

import storage from "@react-native-async-storage/async-storage";

const Stack = createNativeStackNavigator();
const Tab = createBottomTabNavigator();

// export const addressForServer = "https://sike.live";
export const addressForServer = "http://localhost:4000";
// export const addressForServer = ""


const Placeholder = () => {
  return (
    <View style={styles.container}>
      <Text style={styles.text}>Hi!</Text>
    </View>
  );
};

export default function App() {
  const defaultScreen = "Órarend" // replace with "Hírek" in production build

  const [token, setToken] = React.useState("hey");
  const [loading, setLoading] = React.useState(false);
  const [loggedIn, setLoggedIn] = React.useState(false);

  // Create Login function
  async function login({ username, password }) {
    setLoading(true);

    const f = await fetch(`${addressForServer}/login`, {
      body: JSON.stringify({
        username,
        password,
      }),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if(f.status == 403) {
      alert("Hibás jelszó!")
      setLoading(false)
      setLoggedIn(false)
      return
    }

    const token = (await f.json()).token;

    setLoggedIn(true);
    setLoading(false);

    setToken(token);
    await storage.setItem("@token", token);
    await storage.setItem(
      "@keypass",
      JSON.stringify({
        username,
        password,
      })
    );
  }

  // buses refresh
  const [rightNow, setRightNow] = React.useState(Date.now())

  return (
    <NavigationContainer style={styles.container} theme={DarkTheme}>
      <StatusBar />
      <Tab.Navigator initialRouteName={defaultScreen}>
        <Tab.Screen
          name="Kiskönyv"
          component={BookScreen}
          options={{
            tabBarActiveTintColor: "#ffffff",
            headerShown: false,
            tabBarIcon: ({ color, size }) => (
              <Ionicons name="book" size={size} color={color} />
            ),
          }}
        />
        <Tab.Screen
          name="Órarend"
          // component={Schedule}
          options={{
            tabBarActiveTintColor: "#ffffff",
            headerShown: true,
            tabBarIcon: ({ color, size }) => (
              <Ionicons name="calendar" size={size} color={color} />
            ),
          }}
        >
          {() => (
            <Schedule token={token} loading={loading} loggedIn={loggedIn} />
          )}
        </Tab.Screen>
        <Tab.Screen
          name="Hírek"
          component={HomeScreen}
          options={{
            tabBarActiveTintColor: "#ffffff",
            tabBarIcon: ({ color, size }) => (
              <Ionicons name="newspaper" size={size} color={color} />
            ),
          }}
        />

        <Tab.Screen
          name="Buszok"
          component={() => <Buses refresh={rightNow} />}
          options={{
            tabBarActiveTintColor: "#ffffff",
            // headerShown: false,
            tabBarIcon: ({ color, size }) => (
              <Ionicons name="bus" size={size} color={color} />
            ),
            headerRight: () => (
              <View
                style={{
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 16,
                }}
              >
                <Pressable onPress={() => setRightNow(Date.now())}>
                  <Ionicons name="refresh" size={28} color={"white"} />
                </Pressable>
              </View>
            ),
          }}
        ></Tab.Screen>
        <Tab.Screen
          name="Mayor"
          options={{
            tabBarActiveTintColor: "#ffffff",
            // headerShown: false,
            tabBarIcon: ({ color, size }) => (
              <Ionicons name="cog" size={size} color={color} />
            ),
          }}
        >
          {() => (
            <MayorScreen
              login={login}
              token={token}
              loading={loading}
              loggedIn={loggedIn}
              setToken={setToken}
            />
          )}
        </Tab.Screen>
      </Tab.Navigator>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    backgroundColor: "#000",
    // alignItems: "center",
    // justifyContent: "center",
    color: "#fff",
    textDecorationColor: "#fff",
  },
  text: {
    color: "#fff",
  },
});
