
import { ScrollView, View } from "react-native";
import { LongText, Teacher, Title, WeirdText } from "./utils";

export default function Class07() {
  return (
    <ScrollView
      style={{
        padding: 20,
      }}
    ><Title>Az évfolyamon tanító tanárok:</Title>
<Teacher name="Horn György" subjects={['patrónus']} />
<Teacher name="Kardos Éva" subjects={['történelem','patrónus','kisiskola-vezető']} />
<Teacher name="Nádori Gergely" subjects={['természettudomány','tanulásmódszertan','alkotóház','patrónus','kisiskola-vezető']} />
<Teacher name="Sófalvi-Civin Zsuzsa" subjects={['angol nyelv','patrónus']} />
<Teacher name="Teremy László" subjects={['testnevelés','patrónus']} />
<Teacher name="Bekő Edit" subjects={['angol nyelv','alkotóház']} />
<Teacher name="Borsik Lili" subjects={['alkotóház']} />
<Teacher name="Burján Orsolya" subjects={['ének-zene']} />
<Teacher name="Halász Sándor" subjects={['tanulásmódszertan']} />
<Teacher name="Hites Borbála" subjects={['magyar irodalom és nyelvtan']} />
<Teacher name="Juhász Iván" subjects={['magyar irodalom és nyelvtan']} />
<Teacher name="Kuczó Csilla" subjects={['angol nyelv']} />
<Teacher name="Pásztor Judit" subjects={['matematika']} />
<Teacher name="Szabó Imre" subjects={['vizuális kultúra']} />
<Teacher name="Terék Bernadett" subjects={['gépírás']} />
<Teacher name="Tornay Zsuzsanna" subjects={['angol nyelv']} />
<Teacher name="Tóth István" subjects={['alkotóház']} />


<Title>Szülői értekezletek:</Title>
<Teacher name="2022. szeptember 7. (szerda) 17.00 óra" subjects={['']} />
<Teacher name="2023. február 22. (szerda) 17.00 óra" subjects={['']} />
<Teacher name="2023. május 24. (szerda) 17.00 óra" subjects={['']} />



<Title>Fogadóórák:</Title>
<WeirdText>2022. november 9. (szerda) 17.00 óra</WeirdText>
<WeirdText>2023. április 19. (szerda) 17.00 óra</WeirdText>

<Title>Szülői klubok:</Title>
<WeirdText>2022. október 12. (szerda) 17.00 óra</WeirdText>
<WeirdText>2023. március 22. (szerda) 17.00 óra</WeirdText>

<Title>Év végi vizsga:</Title>
<WeirdText>A tanév végén egy a tanuló által választott tantárgyból vizsgát kell tenni. A tanulónak a választását patrónusával egyeztetnie kell.</WeirdText>
<WeirdText>Választható tárgyak: irodalom matematika történelem természettudomány.</WeirdText>
<WeirdText>A vizsga követelményei megtalálhatóak lesznek az iskola honlapján: www.akg.hu/vizsga</WeirdText>
<WeirdText></WeirdText>

    </ScrollView>
    );
  }

