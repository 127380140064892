import { useEffect, useState } from "react";
import {
  Text,
  View,
  TextInput,
  Button,
  StyleSheet,
  Touchable,
  Pressable,
  Image,
} from "react-native";
import storage from "@react-native-async-storage/async-storage";
import Logo from "../../assets/icon.png"


export default function LoginScreen(props) {
  // setToken={setToken}
  // login={login}

  console.log("rendering loginscreen.");

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [remembered, setRemembered] = useState(false);

  useEffect(() => {
    console.log({ username, password });
  }, [username, password]);

  const styles = StyleSheet.create({
    username: {
      backgroundColor: "#3d4240",
      // padding: 5,
      // width: 250,
      // flex: 1,
      marginTop: 10,
      marginBottom: 10,
      width: "80%",
      // height: "30%",
      // margin: 10,
      fontSize: 18,
      padding: 15,
      color: "#ffffff",
      borderRadius: 4
    },
    password: {
      backgroundColor: "#3d4240",
      // padding: 5,
      width: "80%",
      // flex: 1,
      fontSize: 18,
      marginBottom: 20,
      padding: 15,
      color: "#ffffff",
      borderRadius: 4
    },
    container: {
      flex: 1,
      // width: "100%",
      padding: 10,
      justifyContent: "center",
      alignItems: "center",
    },
    text: {
      color: "#ffffff",
      fontWeight: 400,
      fontSize: 22,
      flex: 1,
    },
    button: {
      borderRadius: 4,
      color: "#000000",
      backgroundColor: "#ffffff",
      flex: 1,
      // width: "80%",
      alignItems: "center"
      // width: "100%"
    },
    buttonText: {
      textAlign: "center",
      padding: 10,
      fontWeight: 500,
      fontSize: 24,
    }
  });

  storage.getItem("@keypass").then((keypass) => {
    if(remembered) {
      console.log("not filling in, cause remembered already.")
      return false
    }

    try {
      keypass = JSON.parse(keypass);
      if (keypass.username && keypass.password) {
        setUsername(keypass.username);
        setPassword(keypass.password);

        setRemembered(true);
      }
    } catch (err) {
      return;
    }
  });

  return (
    <View style={styles.container}>
      <View
        style={{
          alignItems: "center",
          padding: 10,
          width: "100%",
        }}
      >
        {/* <Text style={styles.text}>Jelentkezz be mayoros fiókoddal.</Text> */}
        <Image source={Logo} style={{
          height: 200,
          // width: "50%",
          aspectRatio: 1,
          marginBottom: 40

        }}/>
        <TextInput
          value={username}
          onChangeText={setUsername}
          style={styles.username}
          placeholder={"Felhasználónév"}
        />
        <TextInput
          value={password}
          onChangeText={setPassword}
          secureTextEntry={true}
          style={styles.password}
          placeholder={"Jelszó"}
        />
        <Pressable style={{
          width: "80%",
        }} onPress={() => {
          props.login({
            username,
            password,
            setToken: props.setToken
          })
        }}>
          <Text style={[styles.button, styles.buttonText]}>Bejelentkezés</Text>
        </Pressable>
      </View>
    </View>
  );
}
