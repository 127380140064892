import {
  FlatList,
  Modal,
  Pressable,
  SafeAreaView,
  StyleSheet,
  Text,
  TouchableHighlight,
  View,
  Alert,
  ActivityIndicator,
  Image,
} from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { Picker } from "@react-native-picker/picker";
import { useEffect, useState } from "react";

import storage from "@react-native-async-storage/async-storage";
import { addressForServer } from "../App";
import { useIsFocused } from "@react-navigation/native";

import Logo from "../assets/icon.png";

function Text2(props) {
  return (
    <Text
      {...props}
      adjustsFontSizeToFit={true}
      numberOfLines={1}
      style={{
        color: "#ffffff",
        fontSize: 12,
        padding: 5,
      }}
    />
  );
}

function TimeSlot(props) {
  const styles = StyleSheet.create({
    view: {
      height: 80,
      flex: 1,
      borderWidth: 1,
      borderColor: "#3d4240",
      justifyContent: "space-between",
      alignItems: "center",
      maxWidth: 40,
    },
    time: {
      color: "#686c6f",
      fontWeight: "400",
    },
    period: {
      color: "#ffffff",
      fontSize: 21,
      fontWeight: "500",
    },
  });

  return (
    <View style={styles.view}>
      <Text style={styles.time}>{props.start ? props.start : "8:30"}</Text>
      <Text style={styles.period}>{props.period ? props.period : ""}</Text>
      <Text style={styles.time}>{props.end ? props.end : "9:15"}</Text>
    </View>
  );
}

function Lesson(props) {
  const styles = StyleSheet.create({
    view: {
      height: 80,
      flex: 2,
      borderColor: "#3d4240",
      borderWidth: 1,
      alignItems: "center",
      justifyContent: "center",
      // margin: 3
    },
    lessonName: {
      color: "#ffffff",
      fontSize: 10,
      padding: 1,
      paddingHorizontal: 6,
      textAlign: "center",
      fontWeight: 600,
    },
    teacherName: {
      color: "#ffffff",
      fontSize: 10,
      padding: 5,
      textAlign: "center",
      fontWeight: 500,
    },
    lessonInner: {
      height: "100%",
      width: "100%",
      justifyContent: "center",
      backgroundColor: props.name.length > 0 ? `#${props.color}` : "#000000",
      // margin: 3
      borderWidth: 2,
      borderColor: "#000000",
      borderRadius: 5,
    },
  });

  return (
    <TouchableHighlight style={styles.view} onPress={props.onPress}>
      <View style={styles.lessonInner}>
        <Text
          numberOfLines={3}
          textBreakStrategy={"highQuality"}
          style={styles.lessonName}
        >
          {props.name?.toUpperCase()}
        </Text>

        <Text numberOfLines={2} style={styles.teacherName}>
          {props.teacher}
        </Text>
      </View>
    </TouchableHighlight>
  );
}

// one row consists of n number of lessons
function RowOfContent(props) {
  return (
    <View
      style={{
        flex: 6,
        flexDirection: "row",
      }}
    >
      {props.children}
    </View>
  );
}

function RowOfBreaks(props) {
  return (
    <View
      style={{
        flexDirection: "row",
      }}
    >
      <View
        style={{
          flex: 1,
          borderColor: "#3d4240",
          borderWidth: 1,
          height: 30,
          maxWidth: 40,
        }}
      ></View>
      {new Array(5).fill("").map((e) => {
        return (
          <View
            style={{
              flex: 2,
              borderColor: "#3d4240",
              borderWidth: 1,
              height: 30,
            }}
          ></View>
        );
      })}
    </View>
  );
}

function Header(props) {
  // props:
  // dates
  // ids
  // selectedDate
  // selectedId

  const styles = StyleSheet.create({
    container: {
      // flex: 1,
      height: 60,
      backgroundColor: "#000000",
      padding: 5,
      flexDirection: "row",
      // justifyContent: "center",
      // alignContent: "center",
      alignItems: "center",
    },
    picker: {
      backgroundColor: "#131313",
      color: "#ffffff",
      flex: 1,
      padding: 1,
      height: 20,
      borderColor: "#000000",
      borderWidth: 0,
    },
  });

  const [selectedDate, setSelectedDate] = useState(props.selectedDate ?? null);
  const [selectedId, setSelectedId] = useState(props.selectedId ?? null);

  // // useEffect to fix the id in the header
  // useEffect(() => {
  //   console.log("prop selected id changed to", props.selectedId);

  //   if (props.selectedId) {
  //     setSelectedId(props.selectedId.replace(/[^0-9]+/g, ""));
  //   }
  // }, [props.selectedId]);

  // // useEffect to fix the date in the header
  // useEffect(() => {
  //   console.log("prop selected date changed to", props.selectedDate);

  //   if (props.selectedDate) {
  //     setSelectedDate(props.selectedDate);
  //   }
  // }, [props.selectedDate]);

  // useEffect to create request to server
  useEffect(() => {
    if (
      (selectedDate == props.selectedDate && selectedId == props.selectedId) ||
      selectedDate == null ||
      selectedId == null
    ) {
      console.log("not reloading", {
        selDate: selectedDate,
        propDate: props.selectedDate,
        propId: props.selectedId,
        id: selectedId,
      });

      return false;
    }

    console.log({
      msg: "reloading schedule with following requirements",
      date: selectedDate,
      id: selectedId,
      propDate: props.selectedDate,
      propId: props.selectedId,
    });

    props.setRenderedSchedule(false);

    props.setRerenderDetails({
      date: selectedDate,
      id: selectedId,
    });
  }, [selectedDate, selectedId]);

  return (
    <View style={styles.container}>
      {(() => {
        console.log({
          msg: "rendering header with following values",
          selectedId,
          selectedDate,
          propSelectedDate: props.selectedDate,
          propSelectedId: props.selectedId,
        });
      })()}
      {/* <Text
        style={{
          color: "#ffffff",
          fontSize: 18,
          fontWeight: 500,
          padding: 10,
        }}
      >
        Órarend
      </Text> */}
      <Picker
        style={styles.picker}
        selectedValue={selectedId}
        onValueChange={(value) => setSelectedId(value)}
      >
        {/* <Picker.Item label="Java" value="java" />
        <Picker.Item label="JavaScript" value="js" /> */}
        {props.ids
          .filter((e) => e)
          .map((id) => (
            <Picker.Item label={id} value={id.replace(/[^0-9]+/g, "")} />
          ))}
      </Picker>
      <Picker
        style={styles.picker}
        selectedValue={selectedDate}
        onValueChange={(value) => setSelectedDate(value)}
      >
        {props.dates
          .filter((e) => e)
          .map((date) => (
            <Picker.Item label={date} value={date} />
          ))}
      </Picker>
    </View>
  );
}

function HeaderNew(props) {
  // props
  // dates={dates}
  // ids={ids}
  // selectedDate={selectedDate}
  // selectedId={selectedId}

  const [selectedItem, setSelectedItem] = useState(props.selected);

  return (
    <Picker selectedValue={selectedItem} onValueChange={setSelectedItem}>
      <Picker.Item label="one" value="1" />
      <Picker.Item label="two" value="2" />
      <Picker.Item label="three" value="3" />
    </Picker>
  );
}

function Schedule(props) {
  const [mayorSchedule, setMayorSchedule] = useState(null);
  const [rotatedSchedule, setRotatedSchedule] = useState([]);

  const [ids, setIds] = useState([]);
  const [dates, setDates] = useState([]);

  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedId, setSelectedId] = useState(null);

  const [renderedSchedule, setRenderedSchedule] = useState(false);

  const [rerenderDetails, setRerenderDetails] = useState({});

  const [modalVisible, setModalVisible] = useState(false);
  const [modalInfo, setModalInfo] = useState("well hello there!");

  function showModal(newModalInfo) {
    setModalInfo(newModalInfo);
    setModalVisible(true);
  }

  console.log({
    msg: "schedule render",
    token: props.token,
    loggedIn: props.loggedIn,
    loading: props.loading,
  });

  // logged in, request schedule.
  useEffect(async () => {
    if (!props.token || renderedSchedule) {
      console.log("met exit condition, not fetching data.");
      return false;
    }

    console.log("didn't meet exit condition, fetching data.", {
      rerenderDetails,
    });
    let gotSchedule = false;
    while (!gotSchedule) {
      const f = await fetch(`${addressForServer}/schedule`, {
        method: "POST",
        body: JSON.stringify({
          token: props.token,
          date: rerenderDetails?.date,
          id: rerenderDetails?.id,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (f.status == 503) {
        continue;
      }

      const data = await f.json();

      if (data.schedule && data.ids && data.dates) {
        setSelectedDate(data.selectedDate);
        setSelectedId(data.selectedId);

        setMayorSchedule(data.schedule);
        setDates(data.dates);
        setIds(data.ids);
      }

      gotSchedule = true;
    }
  }, [props.token, rerenderDetails]);

  useEffect(() => {
    const lessonColors = {};
    const randomColor = () => Math.floor(Math.random() * 16777215).toString(16);

    if (!mayorSchedule) {
      return;
    }

    const schedule = mayorSchedule.slice(0, 5).map((day) => {
      return day.map((lesson) => {
        // return [lesson.split(",\n")[0].split(" ").slice(0, -1).join(" "), lesson.split(",\n")[1]]

        const data = {
          name: lesson.split(",\n")[0].trim(),
          teacher: lesson.split(",\n")[1]?.trim(),
        };

        if (!lessonColors[data.name]) {
          lessonColors[data.name] = randomColor();
        }

        data.color = lessonColors[data.name];

        return data;
      });
    });

    const rotatedSchedule = [];
    for (let p = 0; p < 14; p++) {
      rotatedSchedule[p] = [];
      for (let d = 0; d < 5; d++) {
        rotatedSchedule[p].push(schedule[d][p]);
      }
    }

    

    setRotatedSchedule(rotatedSchedule);
    setRenderedSchedule(true);

    console.log(rotatedSchedule);
  }, [mayorSchedule]);

  if (!props.token) {
    return (
      <View
        style={{
          flex: 1,
          alignContent: "center",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Image
          source={Logo}
          style={{
            width: 200,
            // width: "50%",
            aspectRatio: 1,
            marginBottom: 20,
          }}
        />
        <Text style={{ color: "#ffffff", fontSize: 20, fontWeight: 500 ,textAlign: "center" }}>
          Az órarendekhez lépj be a Mayorba!
        </Text>
      </View>
    );
    // return <Header />
  }

  if (!renderedSchedule) {
    return (
      <View
        style={{
          flex: 1,
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <ActivityIndicator size={"large"} />
      </View>
    );
  }

  console.log({
    msg: "huh?",
    selectedDate,
    selectedId,
  });

  return (
    <>
      <Pressable onPress={() => setModalVisible(false)}>
        <Modal visible={modalVisible}>
          <Text
            style={{
              backgroundColor: "#000000",
              color: "#ffffff",
              fontSize: 18,
              textAlign: "center",
            }}
          >
            {JSON.stringify(modalInfo)}
          </Text>
        </Modal>
      </Pressable>
      <Header
        dates={dates}
        ids={ids}
        selectedDate={selectedDate.split(" ")[0]}
        selectedId={selectedId?.replace(/[^0-9]+/g, "")}
        setRerenderDetails={setRerenderDetails}
        setRenderedSchedule={setRenderedSchedule}
      />
      <FlatList
        data={rotatedSchedule}
        renderItem={({ item, index }) => (
          <>
            <RowOfContent>
              <TimeSlot period={index + 1} start=" " end=" " />
              {console.log(item) ?? null}
              {item.map((period, periodIndex) => (
                <Lesson
                  name={lessonNameShortener(period?.name ?? "")}
                  teacher={period?.teacher ?? " "}
                  color={period?.color ?? " "}
                  onPress={() => {
                    if (period.name.length < 2) {
                      return;
                    }

                    // showModal({
                    //   name: period?.name,
                    //   teacher: period?.teacher,
                    //   color: period?.color,
                    // });

                    // Alert.alert(period?.name, period.teacher, [
                    //   { text: "OK", onPress: () => console.log("OK Pressed") },
                    // ]);

                    alert([period?.name, period?.teacher].join("\n\n"));
                  }}
                />
              ))}
            </RowOfContent>
            <RowOfBreaks />
          </>
        )}
      />
    </>
  );
}

export default Schedule;

const lessonNameShortener = (initialText) => {
  const toShorten = {
    természettudomány: "TERMTUD",
    matematika: "MATEK",
    angol: "ANGOL",
    történelem: "TÖRI",
    témahét: "TÉMAHÉT",
    ének: "ÉNEK",
    testnevelés: "SPORT",
    informatika: "INFÓ",
    földrajz: "FÖLDRAJZ",
    "magyar nyelv": "NYELVTAN",
    együttéléstan: "EGYÉLTAN",
  };

  Object.entries(toShorten).forEach((e) => {
    if (initialText.toLowerCase().includes(e[0])) {
      initialText = e[1];
    }
  });

  return initialText;
};
