
import { ScrollView, View } from "react-native";
import { LongText, Teacher, Title, WeirdText } from "./utils";

export default function Class9k() {
  return (
    <ScrollView
      style={{
        padding: 20,
      }}
    ><Title>Patrónusok:</Title>
<Teacher name="Gottweisz Tímea" subjects={['Magyar nyelv és irodalom']} />
<Teacher name="Dr. Pethő Szilvia" subjects={['Matematika','spanyol','évfolyam-osztályfőnök']} />
<Teacher name="Sík Eszter" subjects={['']} />
<Teacher name="Tóth István" subjects={['Természettudomány']} />


<Title>Az évfolyamon tanító tanárok:</Title>
<Teacher name="Erdei Edina" subjects={['Természettudomány']} />
<Teacher name="Gombóczné Erdei Mónika" subjects={['Történelem','Etika-Társadalomismeret C cs.']} />
<Teacher name="Csicskárné Mitovics Krisztina" subjects={['Testnevelés']} />
<Teacher name="Dezséri Csaba" subjects={['Informatika']} />
<Teacher name="Fenyvesi Edit" subjects={['Vizuális kultúra']} />
<Teacher name="Hanula Erika" subjects={['Angol nyelv Piros','Zöld csoport']} />
<Teacher name="Helferné Kepecs Judit" subjects={['Domokos Norbert','Angol nyelv Kék csoport']} />
<Teacher name="Huszár-Walla Júlia" subjects={['Angol nyelv Sárga csoport']} />
<Teacher name="Philipp György" subjects={['Ének-zene']} />
<Teacher name="Sallai László" subjects={['Etika-Társadalomismeret A csoport']} />
<Teacher name="Varró Mária" subjects={['Etika-Társadalomismeret B csoport']} />



<Title>Szülői értekezletek:</Title>
<WeirdText>2022. szeptember 21. (szerda) 17 óra</WeirdText>
<WeirdText>2023. február 15. (szerda) 17 óra</WeirdText>

<Title>Fogadóórák:</Title>
<WeirdText>2022. november 9. (szerda) 17 óra</WeirdText>
<WeirdText>2022. április 19. (szerda) 17 óra</WeirdText>

<Title>Szülői klubok:</Title>
<WeirdText>2022. december 7. (szerda) 17 óra</WeirdText>
<WeirdText>2023. május 24. (szerda) 17 óra</WeirdText>

<Title>Év végi vizsga:</Title>
<WeirdText>Vizsgahúzás június 2-án pénteken a sportnapon 14 órakor.</WeirdText>
<WeirdText>Vizsgatárgyak:</WeirdText>
<WeirdText>· irodalom</WeirdText>
<WeirdText>· matematika</WeirdText>
<WeirdText>· történelem</WeirdText>
<WeirdText>· természettudomány</WeirdText>
<WeirdText>A vizsgatárgy húzása csibénként történik. A joker húzása esetén is ebből a négy tantárgyból lehet választani.</WeirdText>
<WeirdText>A vizsga a következő héten csütörtökön vagy pénteken (június 9-10.) lesz erről a szaktanár ad tájékoztatást.</WeirdText>
<WeirdText>A felkészülés önálló de konzultációra van lehetőség. A vizsgakövetelmények és minden információ felkerülnek minden tantárgyból az AKG honlapjára (www.akg.hu/vizsga).</WeirdText>
<WeirdText></WeirdText>

    </ScrollView>
    );
  }

