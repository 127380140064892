import { useEffect, useState } from "react";
import {
  View,
  Text,
  TextInput,
  StyleSheet,
  Button,
  ActivityIndicator,
  FlatList,
  Pressable,
} from "react-native";
import storage from "@react-native-async-storage/async-storage";

import LoginScreen from "./SubScreens/LoginScreen";

import { Ionicons } from "@expo/vector-icons";
import { addressForServer } from "../App";

function Grade(props) {
  const styles = StyleSheet.create({
    base: {
      paddingVertical: 4,
      paddingHorizontal: 6,
      borderRadius: 4,
      fontWeight: 500,
      margin: 5,
    },
    jegy1: {
      backgroundColor: "white",
      color: "blue",
    },
    jegy2: {
      backgroundColor: "white",
      color: "black",
    },
    jegy3: {
      backgroundColor: "white",
      color: "green",
    },
    jegy4: {
      backgroundColor: "white",
      color: "red",
    },
    jegy5: {
      backgroundColor: "gold",
      color: "purple",
    },
  });

  return (
    <Text style={[styles.base, styles[props.type ?? "jegy1"]]}>
      {props.number}
    </Text>
  );
}

function GradeRow(props) {
  return (
    <View
      style={{
        // flex: 1,
        // borderWidth: 2,
        // borderColor: "#ffffff",
        borderTopColor: "#3e3e3e",
        borderBottomColor: "#3e3e3e",
        // borderBottomWidth: 2,
        borderTopWidth: 2,
        flexDirection: "row",
        alignItems: "center",
        padding: 8,
        flexWrap: "wrap",
      }}
    >
      <Text
        style={{
          flex: 1,
          color: "#ffffff",
          padding: 8,
        }}
      >
        {props.name}
      </Text>
      <View
        style={{
          flexDirection: "row",
          flex: 2,
          flexWrap: "wrap",
          // borderRightWidth: 2
        }}
      >
        {/* <Grade number="100%" type="jegy1" /> */}
        {props.grades.map((grade) => (
          <Grade number={grade.grade} type={grade.type} />
        ))}
      </View>
    </View>
  );
}

function Grades(props) {
  const [grades, setGrades] = useState(null);

  useEffect(async () => {
    if (!props.token) return;

    let gotGrades = false;
    while (!gotGrades) {
      const f = await fetch(`${addressForServer}/grades`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: props.token,
        }),
      });

      if (f.status != 503) {
        setGrades(await f.json());
        gotGrades = true;
      }

      //   fetch(`${addressForServer}/grades`, {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //     body: JSON.stringify({
      //       token: props.token,
      //     }),
      //   })
      //     .then((e) => e.json())
      //     .then((e) => setGrades(e));
      // }
    }
  }, [props.token]);

  const [month, setMonth] = useState(1);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (!grades) return;

    setData(
      grades
        .slice(1)
        .slice(0, -1)
        .map((e) => ({
          name: e[0][0].grade,
          grades: e[month],
        }))
    );
  }, [month, grades]);

  if (!grades || !data) {
    return (
      <View
        style={{
          flex: 1,
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <ActivityIndicator size={"large"} />
      </View>
    );
  }

  return (
    <View
      style={{
        flex: 1,
        // borderWidth: 2,
        borderColor: "#ffffff",
      }}
    >
      <View
        style={{
          // borderWidth: 2,
          borderColor: "#ffffff",
          height: 60,
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Pressable
          onPress={() => {
            if (month == 1) {
              return;
            }

            setMonth(month - 1);
          }}
        >
          <Ionicons
            name="play-back"
            size={24}
            color={"white"}
            style={{ padding: 30 }}
          />
        </Pressable>
        {/* <Ionicons name="book" size={24} color={"white"} style={{padding: 30}}/> */}
        <Text
          style={{
            color: "#ffffff",
            fontWeight: 500,
            fontSize: 22,
          }}
        >
          {grades[0][month]}
        </Text>
        <Pressable
          onPress={() => {
            if (month == 11) {
              return;
            }

            setMonth(month + 1);
          }}
        >
          <Ionicons
            name="play-forward"
            size={24}
            color={"white"}
            style={{ padding: 30 }}
          />
        </Pressable>
      </View>
      {/* <GradeRow name="Angol" grades={[{grade: "100%", type: "jegy1"}]} /> */}
      {/* {data.map(e => {
        console.log(e)
        return (
          <GradeRow name={e.name} grades={e.grades ?? []} />
        )
      })} */}
      <FlatList
        data={data.filter((e) => e.name.length > 1)}
        renderItem={({ item, index }) => (
          <GradeRow name={item.name} grades={item.grades} />
        )}
      />
    </View>
  );
}

function LoadingScreen(props) {
  return (
    <View
      style={{
        flex: 1,
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      <ActivityIndicator size={"large"} />
    </View>
  );
}

function LoggedIn(props) {
  return (
    <View
      style={{
        backgroundColor: "#000000",
        flex: 1,
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <Text
        style={{
          color: "#ffffff",
          fontWeight: "400",
          fontSize: 30,
        }}
      >
        Logged in with token {props.token}
      </Text>
    </View>
  );
}

function MayorScreen(props) {
  // props.login = { login };
  // props.token = { token };
  // props.loading = { loading };
  // props.loggedIn = { loggedIn };
  // props.setToken = { setToken };

  console.log({
    msg: "rendering mayorscree",
    token: props.token,
  });
  // return <Grades />;

  if (props.loading) {
    return <LoadingScreen />;
  }

  if (props.loggedIn) {
    return <Grades token={props.token} />;
    // return <LoggedIn token={props.token} />
  }

  if (!props.loggedIn && !props.loading) {
    return <LoginScreen login={props.login} setToken={props.setToken} />;
  }

  return <LoadingScreen />;
}

export default MayorScreen;
