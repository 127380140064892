import { View, Text, StyleSheet } from "react-native";

const mainColor = "#ffffff";

export function Title(props) {
  return (
    <Text
      style={{
        color: mainColor,
        fontSize: 25,
        fontWeight: 500,
        fontFamily: "roboto",
        paddingBottom: 10,
        paddingTop: 10
      }}
    >
      {props.children}
    </Text>
  );
}

export function TeacherText(props) {
  return (
    <Text
      style={{
        color: mainColor,
        fontSize: 18,
        fontWeight: 400,
        fontFamily: "roboto",
      }}
      // numberOfLines={2}
      // adjustsFontSizeToFit={true}
    >
      {props.children}
    </Text>
  );
}

export function SubjectText(props) {
  return (
    <Text
      style={{
        color: mainColor,
        fontSize: 15,
        fontWeight: 300,
        fontFamily: "roboto",
      }}
      numberOfLines={1}
    >
      {props.children}
    </Text>
  );
}

export function Teacher(props) {
  return (
    <View style={{
      paddingTop: 10,
      paddingBottom: 15
    }}>
      <View
        style={{
          // flex: 1,
          flexDirection: "row",
        }}
      >
        <View
          style={{
            flex: 1,
          }}
        >
          <TeacherText>{props.name}</TeacherText>
        </View>
        <View
          style={{
            flex: 1,
          }}
        >
          <SubjectText>{props.subjects[0]}</SubjectText>
        </View>
      </View>
      {props.subjects.slice(1).length > 0 ? props.subjects.slice(1).map(e => {
        return (
          <View style={{flex: 1, flexDirection: "row"}}>
            <View style={{flex: 1}}/>
            <View style={{flex: 1}}> <SubjectText>{e ? e : " "}</SubjectText></View>
          </View>
        )
      }) : null}
    </View>
  );
}

export function WeirdText(props) {
  return (
    <Text
      style={{
        color: mainColor,
        fontSize: 16,
        fontWeight: 300,
        fontFamily: "roboto",
        paddingVertical: 5
      }}
    >
      {props.children}
    </Text>
  );
}
