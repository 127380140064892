import * as React from "react";
import {
  View,
  Text,
  useColorScheme,
  StyleSheet,
  SafeAreaView,
  Image,
  FlatList,
  Pressable,
  Linking,
  Platform,
  ActivityIndicator,
} from "react-native";
import WebView from "react-native-webview";

import * as WebBrowser from "expo-web-browser";
import { addressForServer } from "../App";

function LoadingScreen(props) {
    return (
      <View
        style={{
          flex: 1,
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <ActivityIndicator size={"large"} />
      </View>
    );
  }  

function PieceOfNews(props) {
  const styles = StyleSheet.create({
    container: {
      padding: 15,
      // borderWidth: 2,
      marginHorizontal: 15,
      marginBottom: 15,
      marginVertical: 15,
      borderRadius: 10,
      backgroundColor: "#1c1c1e",
      shadowOpacity: 0.2,
      shadowRadius: 10,
      shadowOffset: {
        height: 10,
        width: 10,
      },
    },
    image: {
      width: "100%",
      height: 150,
      borderRadius: 10,
    },
    title: {
      fontSize: 21,
      fontWeight: "bold",
      paddingBottom: 5,
      paddingTop: 5,
      color: "#ffffff",
      fontFamily: "roboto",
    },
    desc: {
      fontWeight: "400",
      color: "#adb2b8",
      paddingBottom: 8,
    },
    date: {
      fontWeight: "400",
      fontStyle: "italic",
      color: "#656c74",
    },
  });

  return (
    <Pressable
      onPress={async () => {
        await WebBrowser.openBrowserAsync(props.link);
      }}
    >
      <View style={styles.container}>
        <Image source={{ uri: props.img }} style={styles.image} />
        <Text style={styles.title}>{props.title}</Text>
        <Text style={styles.desc}>{props.desc}</Text>
        <Text style={styles.date}>{props.date}</Text>
      </View>
    </Pressable>
  );
}

function HomeScreen() {
  const styles = StyleSheet.create({
    container: {
      flex: 1,
      // paddingTop: 21,
      backgroundColor: "#080808",
    },
    webview: {
      width: 0,
      height: 0,
    },
  });

  const [news, setNews] = React.useState([]);

  const renderItem = ({ item }) => {
    return (
      <PieceOfNews
        img={item.img}
        title={item.title}
        link={item.link}
        desc={item.desc}
        date={item.date}
        key={item.img}
      />
    );
  };

  const [loading, setLoading] = React.useState(true);

  if (loading) {
    // console.log(fetch("https://telex.hu"))
    fetch(`${addressForServer}/news`)
      .then((e) => e.json())
      .then((e) => setNews(e))
      .then((e) => setLoading(false))
    return <LoadingScreen />
  }

  return (
    <View style={styles.container}>
      {/* <Text style={{color: "#ffffff"}}>
                hi
            </Text> */}
      {/* <StatusBar style='dark'/> */}

      <FlatList data={news} renderItem={renderItem} />
    </View>
  );
}

export default HomeScreen;
