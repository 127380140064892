
import { ScrollView, View } from "react-native";
import { LongText, Teacher, Title, WeirdText } from "./utils";

export default function Class11ny() {
  return (
    <ScrollView
      style={{
        padding: 20,
      }}
    ><Title>Patrónusok:</Title>
<Teacher name="Birloni Szilvia" subjects={['']} />
<Teacher name="Driszkuné Szilágyi Éva" subjects={['Testnevelés']} />
<Teacher name="Hugli Bálint" subjects={['']} />
<Teacher name="Kuczó Csilla" subjects={['Kisiskola-képviselő']} />
<Teacher name="Lőrinc László" subjects={['']} />
<Teacher name="Nógrádi Zoltán" subjects={['Kisiskola-képviselő']} />
<Teacher name="Vankó Szilvia" subjects={['Testnevelés']} />
<Teacher name="Zaccomer Mária" subjects={['']} />


<Title>Az évfolyamon tanító tanárok:</Title>
<Teacher name="Halász Sándor" subjects={['Mozgóképkultúra és médiaismeret','Informatika','Digitális kultúra']} />
<Teacher name="Laffer Zsuzsanna" subjects={['Német nyelv']} />
<Teacher name="Zsisku Irén" subjects={['Német nyelv']} />
<Teacher name="Széles Gábor" subjects={['Német nyelv']} />
<Teacher name="Stock Martin" subjects={['Német nyelv']} />
<Teacher name="Kálmán Éva" subjects={['Spanyol nyelv']} />
<Teacher name="Zábráczki Anett" subjects={['Spanyol nyelv']} />
<Teacher name="Zelkó Katalin" subjects={['Spanyol nyelv']} />
<Teacher name="Domokos Norbert" subjects={['Spanyol nyelv']} />
<Teacher name="Catherine Paule Chamfrault" subjects={['Francia nyelv']} />
<Teacher name="Puskás Annamária" subjects={['Francia nyelv']} />
<Teacher name="Virginie Marie-Rose Sette" subjects={['Francia nyelv']} />
<Teacher name="Szalontai Ágnes" subjects={['Francia nyelv']} />
<Teacher name="Bekő Edit" subjects={['Angol nyelv']} />
<Teacher name="Herczeghné Kozma Andrea" subjects={['Angol nyelv']} />
<Teacher name="Kuczó Csilla" subjects={['Angol nyelv']} />
<Teacher name="Surányi Szabolcs" subjects={['Informatika','Digitális kultúra']} />



<Title>Éves vizsgák</Title>
<WeirdText>A1 vizsga: október 21.</WeirdText>
<WeirdText>A2 vizsga: december 8-9.</WeirdText>
<WeirdText>B1 vizsga: március 3-6.</WeirdText>
<WeirdText>B2 vizsga: május 25-26.</WeirdText>

<Title>Idegen nyelvi prezentációk</Title>
<WeirdText>Január 26-27.</WeirdText>
<WeirdText>Április 27-28.</WeirdText>

<Title>Fogadóórák:</Title>
<WeirdText>2022/ November 09.</WeirdText>
<WeirdText>2022/ January 18.</WeirdText>
<WeirdText>2022/ March 22.</WeirdText>

<Title>Projektbemutatók:</Title>
<WeirdText>május 8. - május 12.</WeirdText>

<Title>Értékelőosztás</Title>
<WeirdText>értékelő 1. – 2022. október 5.</WeirdText>
<WeirdText>vizsgaértesítő 1. – 2022. november 2.</WeirdText>
<WeirdText>vizsgaértesítő 2. – 2023. január 4.</WeirdText>
<WeirdText>félévi értékelő</WeirdText>
<WeirdText>vizsgaértesítő 3. – 2023. március 13.</WeirdText>
<WeirdText>év végi értékelő  (B2 vizsga is)</WeirdText>
<WeirdText></WeirdText>

    </ScrollView>
    );
  }

