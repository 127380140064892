
import { ScrollView, View } from "react-native";
import { LongText, Teacher, Title, WeirdText } from "./utils";

export default function Class12n() {
  return (
    <ScrollView
      style={{
        padding: 20,
      }}
    ><Title>Patrónusok:</Title>
<Teacher name="Bajkó Bálint" subjects={['Történelem A1']} />
<Teacher name="Cserné Tatai Réka" subjects={['Biológia A']} />
<Teacher name="Horn Ágnes" subjects={['Gazdasági ismeretek A','Kisiskola-képviselő']} />
<Teacher name="Szalontai Ágnes" subjects={['Magyar nyelv és irodalom A','Francia nyelv']} />
<Teacher name="Szondi György" subjects={['Matematika A3','Kisiskola-képviselő']} />


<Title>Az évfolyamon tanító tanárok:</Title>
<Teacher name="Baross Dorottya" subjects={['Pszichológia']} />
<Teacher name="Birloni Szilvia" subjects={['Matematika B3']} />
<Teacher name="Bodonczy Andrea" subjects={['Magyar nyelv és irodalom B1']} />
<Teacher name="Hantos István" subjects={['Történelem B3','Jogom van?','Társadalomismeret']} />
<Teacher name="Hegymegi Mariann" subjects={['Fizika A','Kémia A']} />
<Teacher name="Hites Borbála" subjects={['Magyar nyelv és irodalom B2']} />
<Teacher name="Horn György" subjects={['Történeti társadalomismeret']} />
<Teacher name="Juhász Iván" subjects={['Magyar nyelv és irodalom B3']} />
<Teacher name="Kardos Éva" subjects={['Történelem B4']} />
<Teacher name="Kelemen Veronika" subjects={['Kémia A']} />
<Teacher name="Létay Károly" subjects={['Magyar nyelv és irodalom B4']} />
<Teacher name="Lőrinc László" subjects={['Életmódtörténet']} />
<Teacher name="Mendelovics Zsuzsanna" subjects={['Matematika B2']} />
<Teacher name="Nádori Gergely" subjects={['Biológia A']} />
<Teacher name="Nagy Mélykuti Luca" subjects={['Történelem B1']} />
<Teacher name="Nógrádi Zoltán" subjects={['Matematika B1','A1']} />
<Teacher name="Ozorai Judit" subjects={['Magyar nyelv és irodalom B5','Médiaismeret']} />
<Teacher name="Pásztor Judit" subjects={['Matematika A1']} />
<Teacher name="Puskás Annamária" subjects={['Francia nyelv']} />
<Teacher name="Reiter Dániel" subjects={['Matematika A2']} />
<Teacher name="Stock Martin" subjects={['Spanyol nyelv B2']} />
<Teacher name="Sulyok Pap Zsuzsa" subjects={['Művészettörténet']} />
<Teacher name="Surányi Szabolcs" subjects={['Informatika A']} />
<Teacher name="Szabó Imre" subjects={['Rajz és vizuális kultúra']} />
<Teacher name="Szalontai Ágnes" subjects={['Magyar nyelv és irodalom A','Francia nyelv']} />
<Teacher name="Zelkó Katalin" subjects={['Spanyol nyelv']} />
<Teacher name="Zsisku Irén" subjects={['Német nyelv']} />



<Title>Szülői értekezletek:</Title>
<WeirdText>2022. szeptember 14. (szerda) 17:30 óra</WeirdText>
<WeirdText>2023. február 22.(szerda) 17:30 óra</WeirdText>

<Title>Fogadóórák:</Title>
<WeirdText>2022. november 9. (szerda) 17 óra</WeirdText>
<WeirdText>2023. január 11. (szerda) 17 óra</WeirdText>

<Title>Szülői klub:</Title>
<WeirdText>2022. október 5. (szerda) 17:00 óra (Tervezett)</WeirdText>

<Title></Title>

<Title></Title>

<Title></Title>

    </ScrollView>
    );
  }

