
import { ScrollView, View } from "react-native";
import { LongText, Teacher, Title, WeirdText } from "./utils";

export default function Class10_7() {
  return (
    <ScrollView
      style={{
        padding: 20,
      }}
    ><Title>Patrónusok:</Title>
<Teacher name="Hites Borbála" subjects={['Magyar nyelv és irodalom']} />
<Teacher name="dr. Stefány Judit" subjects={['Történelem','Etika-társadalomismeret (1. csop.)']} />
<Teacher name="Szűcs Tamás" subjects={['Évfolyam-osztályfőnök','Piacgazdasági alapismeretek']} />
<Teacher name="Tornay Zsuzsanna" subjects={['Angol nyelv (piros és kék csop.)']} />


<Title>Az évfolyamon tanító tanárok:</Title>
<Teacher name="Bodonczy Andrea" subjects={['Etika-társadalomismeret (2. csop.)']} />
<Teacher name="Burján Orsolya" subjects={['Ének-zene']} />
<Teacher name="Chrenkó Tímea" subjects={['Természettudomány (Hupa)']} />
<Teacher name="Cserné Tatai Réka" subjects={['Természettudomány (Hepe)']} />
<Teacher name="Dezséri Csaba" subjects={['Informatika']} />
<Teacher name="Hegymegi Istvánné" subjects={['Természettudomány']} />
<Teacher name="Horn Ágnes" subjects={['Etika-társadalomismeret (3. és 4. csop.)']} />
<Teacher name="Nádori Gergely" subjects={['Természettudomány']} />
<Teacher name="Nógrádi Zoltán" subjects={['Matematika (Hupa)']} />
<Teacher name="Reiter Dániel" subjects={['Matematika (Hepe)']} />
<Teacher name="Surányi Szabolcs" subjects={['Informatika']} />
<Teacher name="Szabó Imre" subjects={['Vizuális kultúra']} />
<Teacher name="Teremy László" subjects={['Testnevelés']} />
<Teacher name="Valasek Zsuzsanna" subjects={['Angol nyelv (zöld és sárga csop.)']} />



<Title>Szülői értekezletek:</Title>
<WeirdText>2022. szeptember 15. (csütörtök) 17 óra</WeirdText>
<WeirdText>2023. február 23. (csütörtök) 17 óra</WeirdText>

<Title>Fogadóórák:</Title>
<WeirdText>2022. november 9. (szerda) 17 óra</WeirdText>
<WeirdText>2023. április 12. (szerda) 17 óra</WeirdText>

<Title>Szülői klubok:</Title>
<WeirdText>2022. november 23. (szerda) 17 óra</WeirdText>
<WeirdText>2023. május 24. (szerda) 17 óra</WeirdText>

<Title>Év végi vizsga:</Title>
<WeirdText>Vizsgahúzás június 2-án pénteken a sportnapon 14 órakor.</WeirdText>
<WeirdText>Vizsgatárgyak:</WeirdText>
<WeirdText>· irodalom</WeirdText>
<WeirdText>· matematika</WeirdText>
<WeirdText>· történelem</WeirdText>
<WeirdText>· természettudomány</WeirdText>
<WeirdText>A vizsgatárgy húzása csibénként történik.</WeirdText>
<WeirdText>A vizsga a következő héten csütörtökön vagy pénteken (június 9-10.) lesz erről a szaktanár ad tájékoztatást.</WeirdText>
<WeirdText>A felkészülés önálló de konzultációra van lehetőség. A vizsgakövetelmények és minden információ felkerülnek minden tantárgyból az AKG honlapjára (www.akg.hu/vizsga).</WeirdText>
<WeirdText></WeirdText>

    </ScrollView>
    );
  }

