
import { ScrollView, View } from "react-native";
import { LongText, Teacher, Title, WeirdText } from "./utils";

export default function Class08() {
  return (
    <ScrollView
      style={{
        padding: 20,
      }}
    ><Title>Patrónusok:</Title>
<Teacher name="Baranyai István" subjects={['Évfolyam-osztályfőnök','Matematika']} />
<Teacher name="Bodonczy Andrea" subjects={['Magyar nyelv és irodalom','Tanulásmódszertan']} />
<Teacher name="Nagy Mélykuti Luca" subjects={['Történelem','Tanulásmódszertan']} />
<Teacher name="Valasek Zsuzsanna" subjects={['Angol nyelv','Tanulásmódszertan']} />


<Title>Az évfolyamon tanító tanárok:</Title>
<Teacher name="Chrenkó Tímea" subjects={['Természettudomány']} />
<Teacher name="Cserné Tatai Réka" subjects={['Természettudomány']} />
<Teacher name="Dezséri Csaba" subjects={['Informatika']} />
<Teacher name="Burján Orsolya" subjects={['Ének-zene']} />
<Teacher name="Sinka Noémi" subjects={['Testnevelés']} />
<Teacher name="Szabó Imre" subjects={['Vizuális kultúra']} />
<Teacher name="Zaccomer Mária" subjects={['Angol nyelv']} />



<Title>Szülői értekezletek:</Title>
<WeirdText>2022. szeptember 14. (szerda) 17.00 óra</WeirdText>
<WeirdText>2023. február 22. (szerda) 17.00 óra</WeirdText>

<Title>Fogadóórák:</Title>
<WeirdText>2022. november 16. (szerda) 17.00 óra</WeirdText>
<WeirdText>2023. április 19. (szerda) 17.00 óra</WeirdText>

<Title>Év végi vizsga:</Title>
<WeirdText>Egy tantárgyból a tanév végén kötelező vizsgát tenni. A vizsgatárgy húzása csibénként történik. A vizsgakövetelmények a vizsgacsoportok Teams felületére kerülnek.</WeirdText>
<WeirdText>Vizsgatárgyak: irodalom matematika történelem természettudomány.  A vizsga időpontja június 8. (csütörtök) vagy június 9. (péntek).</WeirdText>
<WeirdText></WeirdText>

    </ScrollView>
    );
  }

