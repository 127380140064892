
import { ScrollView, View } from "react-native";
import { LongText, Teacher, Title, WeirdText } from "./utils";

export default function Class12() {
  return (
    <ScrollView
      style={{
        padding: 20,
      }}
    ><Title>Patrónusok:</Title>
{/* <Teacher name="12/7 Veni-Vidi-Vici évfolyam" subjects={['12/5 Umpa-Lumpa évfolyam']} /> */}
<Teacher name="Chrenkó Tímea" subjects={['Kisikola-képviselő','Halász Sándor','Informatika A']} />
<Teacher name="Jakab Péter" subjects={['Testnevelés','Puskás Annamária','Francia nyelv','Francia színház']} />
<Teacher name="Pásztor Judit" subjects={['Matematika A','Sulyok Pap Zsuzsa','Kisikola-képviselő','Művészettörténet']} />
<Teacher name="Sinka Noémi" subjects={['Testnevelés','Tüskéné Molnár Anita']} />
<Teacher name="Széles Gábor" subjects={['Német nyelv']} />
<Teacher name="Zelkó Katalin" subjects={['Spanyol nyelv']} />


<Title>Az évfolyamon tanító tanárok:</Title>
<Teacher name="Bajkó Bálint" subjects={['Történelem B']} />
<Teacher name="Balla Fanni" subjects={['Magyar nyelv és irodalom B']} />
<Teacher name="Baranyai István" subjects={['Matematika B']} />
<Teacher name="Baross Dorottya" subjects={['Pszichológia']} />
<Teacher name="Birloni Szilva" subjects={['Matematika B']} />
<Teacher name="Cserné Tatai Réka" subjects={['Biológia A']} />
<Teacher name="Hantos István" subjects={['Jogom van?!']} />
<Teacher name="Hegymegi Mariann" subjects={['Fizika A','Kémia A']} />
<Teacher name="Hites Borbála" subjects={['Magyar nyelv és irodalom A']} />
<Teacher name="Horn György" subjects={['Történeti társadalomismeret']} />
<Teacher name="Hugli Bálint" subjects={['Történelem B']} />
<Teacher name="Juhász Iván" subjects={['Magyar nyelv és irodalom B']} />
<Teacher name="Kálmán Éva" subjects={['Spanyol nyelv']} />
<Teacher name="Kardos Éva" subjects={['Történelem A']} />
<Teacher name="Kelemen Veronika" subjects={['Kémia A']} />
<Teacher name="Laffer Zsuzsa" subjects={['Német nyelv']} />
<Teacher name="Lőrinc László" subjects={['Történelem B','Életmódtörténet']} />
<Teacher name="Mendelovics Zsuzsanna" subjects={['Matematika A']} />
<Teacher name="Nádori Gergely" subjects={['Biológia A']} />
<Teacher name="Nagy Mélykuti Luca" subjects={['Történelem B']} />
<Teacher name="Nógrádi Zoltán" subjects={['Matematika B']} />
<Teacher name="Ozorai Judit" subjects={['Magyar nyelv és irodalom  B','Médiaismeret']} />
<Teacher name="Pásztor Judit" subjects={['Matematika A1']} />
<Teacher name="Puskás Annamária" subjects={['Francia nyelv']} />
<Teacher name="Sík Eszter" subjects={['Magyar nyelv és irodalom B']} />
<Teacher name="Stefany Judit" subjects={['Történelem A']} />
<Teacher name="Stock Martin" subjects={['Német nyelv']} />
<Teacher name="Sulyok Pap Zsuzsa" subjects={['Művészettörténet']} />
<Teacher name="Surányi Szabolcs" subjects={['Matematika A','Informatika A']} />
<Teacher name="Szabó Imre" subjects={['Rajz és vizuális kultúra']} />
<Teacher name="Szalontai Ágnes" subjects={['Magyar nyelv és irodalom B','Francia nyelv']} />
<Teacher name="Szűcs Tamás" subjects={['Gazdasági ismeretek']} />
<Teacher name="Zábráczki Anett" subjects={['Spanyol nyelv']} />
<Teacher name="Zelkó Katalin" subjects={['Spanyol nyelv']} />
<Teacher name="Zsisku Irén" subjects={['Német nyelv']} />



<Title>Szülői értekezletek:</Title>
<WeirdText>2022. szeptember 21. (szerda) 17:30 óra</WeirdText>
<WeirdText>2023. február 22.(szerda) 17:30 óra</WeirdText>
<WeirdText>2023. június 7. (szerda) 17:30 óra a Veni-Vidi-Vici évfolyamnak</WeirdText>

<Title>Fogadóórák:</Title>
<WeirdText>2022. november 16. (szerda) 17 óra</WeirdText>
<WeirdText>2023. március 22. (szerda) 17 óra</WeirdText>

<Title></Title>

<Title></Title>

<Title></Title>

<Title></Title>

<Title></Title>

    </ScrollView>
    );
  }

